<template>
    <div class="header mui-flex aic jcsb">
        <div class="logo"
             @click="toConsole">
            唯一网络
        </div>
        <div class="user">
            <el-dropdown placement="bottom"
                         @command="handleCommand"
                         szie="medium">
                <div class="mui-flex aic">
                    <div class="user__icon mui-flex aic jcc">
                        <img src="~assets/imgs/home/default-pic.png"
                             alt="">
                        <span class="user-name">{{userInfo.user_name}}</span>
                    </div>
                </div>

                <el-dropdown-menu slot="dropdown">
                    <div class="haeder-user__name tc">
                        <div class="haeder-user__name-icon tc">
                            <img class="w100"
                                 src="~assets/imgs/home/default-pic.png"
                                 alt="">
                        </div>
                        <div>{{userInfo.user_name}}</div>
                    </div>
                    <el-dropdown-item command="resetPwd">
                        <div class="mui-flex aic m-dropdown-item">
                            <i class="el-icon-edit-outline"
                               style="font-size: 16px;color: #999;"></i>
                            <span style="margin-left: 5px;">重置密码</span>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item command="logout">
                        <div class="mui-flex aic m-dropdown-item">
                            <img class="logout-icon"
                                 src="~assets/imgs/home/logout.png"
                                 alt="">
                            退出
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- 密码修改 -->
        <el-dialog title="密码重置"
                   :visible.sync="isRestPwd"
                   :close-on-click-modal="false"
                   @close="resetFormReset('resetForm')"
                   width="600px">
            <el-form :model="resetForm"
                     ref="resetForm"
                     :rules="resetFormRules"
                     label-width="130px"
                     class="demo-ruleForm">
                <el-form-item label="旧密码"
                              prop="oldPwd">
                    <el-input type="password"
                              style="width: 300px;"
                              placeholder="请输入旧密码"
                              v-model="resetForm.oldPwd"></el-input>
                </el-form-item>
                <el-form-item label="新密码"
                              prop="newPwd">
                    <el-input type="password"
                              style="width: 300px;"
                              placeholder="请输入新密码"
                              v-model="resetForm.newPwd"></el-input>
                </el-form-item>
                <el-form-item label="确认密码"
                              prop="newPwd2">
                    <el-input type="password"
                              style="width: 300px;"
                              placeholder="请再次输入新密码"
                              v-model="resetForm.newPwd2"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="resetFormReset('resetForm')">取 消</el-button>
                <el-button type="primary"
                           @click="resetFormSubmit('resetForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { token } from 'utils/localkey';
import { isPwd } from 'utils/regular';
import { update_pwd } from 'api/global';
export default {
    computed: {
        ...mapState('user', ['userInfo']),
        ...mapState('global', ['title'])
    },
    data() {
        // 自定义密码格式校验
        const validatePwd = (rule, value, callback) => {
            const isB = isPwd(value);
            return isB ? callback() : callback(new Error('格式错误，字母+数字+特殊符号'));
        };
        const contrastPwd = (rule, value, callback) => {
            const isB = value === this.resetForm.newPwd;
            return isB ? callback() : callback(new Error('两次密码不一致'));
        };
        return {
            isRestPwd: false,
            resetForm: {
                oldPwd: '',
                newPwd: '',
                newPwd2: ''
            },
            resetFormRules: {
                oldPwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: validatePwd, trigger: 'blur' }
                ],
                newPwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: validatePwd, trigger: 'blur' }
                ],
                newPwd2: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: validatePwd, trigger: 'blur' },
                    { validator: contrastPwd, trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        ...mapMutations('user', ['resetUser']),
        toConsole() {
            this.$router.replace('/console');
        },
        handleCommand(val) {
            if (val === 'logout') {
                localStorage.removeItem(token);
                this.resetUser();
                this.$router.replace('/login');
            } else if (val === 'resetPwd') {
                this.isRestPwd = true;
            }
        },
        resetFormSubmit(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const { oldPwd, newPwd } = this.resetForm;
                    const { code } = await update_pwd({
                        old_pwd: oldPwd,
                        new_pwd: newPwd
                    });
                    if (code === 200) {
                        this.$msg.success('操作成功！');
                        this.resetFormReset(formName);
                        setTimeout(() => {
                            localStorage.removeItem('token');
                            this.$router.replace('/login');
                        }, 800);
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetFormReset(formName) {
            this.isRestPwd = false;
            this.resetForm = {
                oldPwd: '',
                newPwd: '',
                newPwd2: ''
            };
            this.$refs[formName].resetFields();
        }
    },
    mounted() {
    }
};
</script>

<style lang="less" scoped>
.header {
    height: 52px;
    padding: 0 70px 0 40px;
    background: #394053;
    font-size: 22px;
    color: #fff;
    .logo {
        cursor: pointer;
    }
}
.user {
    height: 30px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    &__icon {
        img {
            display: block;
            width: 30px;
            margin: 0;
        }
        .user-name {
            margin-left: 12px;
            color: #fff;
        }
    }
    &__operate {
        position: absolute;
        right: 2px;
        top: -3px;
        font-size: 14px;
        color: #fff;
    }
}

.haeder-user__name {
    padding: 15px 0;
    width: 200px;
    border-bottom: 1px solid @Bd;
    &-icon {
        width: 50px;
        margin: 0 auto 10px;
    }
}

/deep/ .m-dropdown-item {
    height: 46px;
    // &:hover {
    //     .el-dropdown-menu__item i {
    //         color: red;
    //     }
    //     color: red;
    // }
}
/deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #606266 !important;
}

/deep/ .el-dropdown-menu__item {
    display: flex;
    align-items: center;
}
/deep/ .logout-icon {
    width: 14px;
    margin-left: 2px;
    margin-right: 10px;
}
</style>
