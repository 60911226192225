<template>
    <div class="home">
        <Header />
        <div class="main mui-flex">
            <SideBar :baseSidebar="baseSidebar"
                     :adminSideBar="adminSideBar"
                     :managerSideBar="managerSideBar" />
            <div class="con-wrap mui-flex__cell"
                 :class="{noPadding: isNoBg}">
                <div class="con"
                     :class="{'no-bg': isNoBg}">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../components/Sidebar.vue';
import Header from '../components/Header.vue';


export default {
    name: 'Console',
    components: {
        SideBar,
        Header
    },
    watch: {
        $route: {
            handler(route) {
                const { name } = route;
                this.isNoBg = this.noBgRouteName.includes(name);
            },
            immediate: true // 在初始化时立刻执行handler
        }
    },
    data() {
        return {
            // 基础菜单
            baseSidebar: [
                {
                    name: '首页',
                    path: '/console',
                    icon: 'el-icon-s-home'
                }
            ],
            adminSideBar: [
                {
                    name: '企业管理',
                    path: '/businessManage',
                    icon: 'el-icon-office-building'
                },
                {
                    name: '用户反馈',
                    path: '/feedback',
                    icon: 'el-icon-edit-outline'
                }
            ],
            managerSideBar: [
                {
                    name: '员工管理',
                    path: '/staffManage',
                    icon: 'el-icon-user'
                },
                {
                    name: '企业额度',
                    path: '/modelQuotaManage',
                    icon: 'el-icon-odometer'
                }
            ],
            noBgRouteName: ['Console'],
            isNoBg: true
        };
    },
    mounted() {
    }
};
</script>
<style lang="less" scoped>
.home {
    overflow-y: hidden;
    height: 100%;
    .main {
        position: relative;
        background: #f4f6f9;
        height: calc(100% - 52px);
    }
    .con-wrap {
        height: 100%;
        padding: 10px;
        &.noPadding {
            padding: 0;
        }
        .con {
            height: 100%;
            background: #fff;
            &.no-bg {
                background: inherit;
            }
        }
    }
}
</style>
