<template>
    <div class="home">
        <Header />
        <div class="main mui-flex">
            <SideBar :baseSidebar="baseSidebar"
                     title="企业知识库" />
            <div class="con-wrap mui-flex__cell"
                 :class="{noPadding: isIndex}">
                <div class="con"
                     :class="{'no-bg': isIndex}">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from '../components/Sidebar.vue';
import Header from '../components/Header.vue';


export default {
    name: 'Home',
    components: {
        SideBar,
        Header
    },
    watch: {
        $route: {
            handler(route) {
                const { name } = route;
                this.isIndex = this.IndexRouteNames.includes(name);
            },
            immediate: true // 在初始化时立刻执行handler
        }
    },
    data() {
        return {
            isIndex: true, // 是否处于首页
            IndexRouteNames: ['AiChat', 'KnowledgeChat'],
            // 基础菜单
            baseSidebar: [
                {
                    name: '智能会话',
                    path: '/aiChat',
                    icon: 'el-icon-chat-dot-round'
                },
                {
                    name: '知识库管理',
                    path: '/knowledgeManage',
                    icon: 'el-icon-notebook-2'
                }
            ],
            adminSideBar: [],
            managerSideBar: []
        };
    },
    mounted() {
    }
};
</script>
<style lang="less" scoped>
.home {
    overflow-y: hidden;
    height: 100%;
    .main {
        position: relative;
        background: #f4f6f9;
        height: calc(100% - 52px);
    }
    .con-wrap {
        padding: 10px;
        height: 100%;
        &.noPadding {
            padding: 0;
        }
        .con {
            height: 100%;
            background: #fff;
            position: relative;
            &.no-bg {
                background: inherit;
            }
        }
    }
}
</style>
