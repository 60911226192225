<template>
    <div class="sidebar clear-fix">
        <div v-if="title"
             class="logo mui-flex aic">
            <span v-if="isCollapseTitle"
                  class="logo-name-2 mui-flex aic jcc">{{title.slice(0,1)}}</span>
            <span class="logo-name"
                  v-else>{{title}}</span>
        </div>
        <el-menu class="menu"
                 @open="handleOpen"
                 @close="handleClose"
                 @select="handleClick"
                 :collapse="isCollapse"
                 :default-active="activeIndex"
                 router
                 :unique-opened="true"
                 background-color="#363747"
                 text-color="#ffffff"
                 active-text-color="#409eff">
            <el-menu-item v-for="item in sidebarArr"
                          :key="item.path"
                          :index="item.path">
                <i :class="item.icon"></i>
                <span slot="title">{{item.name}}</span>
            </el-menu-item>
        </el-menu>
        <div class="toggle"
             @click="isCollapse = !isCollapse">
            <i v-show="isCollapse"
               class="el-icon-caret-right"></i>
            <i v-show="!isCollapse"
               class="el-icon-caret-left"></i>
        </div>
    </div>

</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { Menu, MenuItem, Submenu, MenuItemGroup } from 'element-ui';
export default {
    props: {
        title: String,
        baseSidebar: {
            type: Array,
            default: () => []
        },
        adminSideBar: {
            type: Array,
            default: () => []
        },
        managerSideBar: {
            type: Array,
            default: () => []
        }
    },
    components: {
        'el-menu': Menu,
        'el-menu-item': MenuItem
        // 'el-submenu': Submenu
    },
    data() {
        return {
            indexRouteNames: ['AiChat', 'KnowledgeChat'], // 首页的路由名称
            activeIndex: '', // 选中的index
            isCollapse: false,
            isChangeNormalWidth: false,
            isCollapseTitle: false
        };
    },
    computed: {
        ...mapGetters(['isManager', 'isAdmin', 'isSuperManager']),
        sidebarArr() {
            let arr = this.baseSidebar;
            if (this.isManager || this.isSuperManager) {
                arr = [...arr, ...this.managerSideBar];
            } else if (this.isAdmin) {
                arr = [...arr, ...this.adminSideBar];
            }
            return arr;
        }
    },
    watch: {
        $route: {
            handler(route) {
                const { path, name } = route;
                this.activeIndex = this.indexRouteNames.includes(name) ? '/aiChat' : path;
            },
            immediate: true // 在初始化时立刻执行handler
        },
        isCollapse(val) {
            if (!val) {
                this.isChangeNormalWidth = true;
            } else {
                this.isChangeNormalWidth = false;
            }
            setTimeout(() => {
                this.isCollapseTitle = val;
            }, 200);
        }
    },
    methods: {
        ...mapMutations('user', ['resetUser']),
        handleClick(index) {
            console.log(index);
            this.activeIndex = index;
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        }

    }
};
</script>

<style lang="less" scoped>
.sidebar {
    position: relative;
    height: 100%;
    top: 0;
    background: #363747;
    &:hover {
        .toggle {
            display: block;
        }
    }
}
.logo {
    position: relative;
    height: 52px;
    // padding-left: 20px;
    font-weight: bold;
    color: #fff;
    font-size: 16px;
    &-name {
        padding-left: 25px;
    }
    &-name-2 {
        padding: 4px;
        width: 32px;
        height: 32px;
        margin: 0 auto;
        border: 1px solid #fff;
        border-radius: 100%;
    }
}
.toggle {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -7px;
    width: 12px;
    height: 40px;
    cursor: pointer;
    background-color: #fff;
    z-index: 9;
    border-radius: 6px;
    border: 1px solid @Bd;
    font-size: 12px;
    i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes widthNormal {
    from {
        width: 0;
    }
    to {
        width: 132px;
    }
}
</style>
<style lang="less">
.el-menu {
    border-right: 0;
}
.el-submenu__title i,
.el-menu-item i {
    color: #fff;
}
.menu:not(.el-menu--collapse) {
    width: 200px;
}
</style>
